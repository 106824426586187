import React from 'react'

// layouts
import Grid from 'layouts/grid/Grid'

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import SideNavigation from '../../../modules/sideNavigation/SideNavigation'
import VideoCard from 'modules/videoCard/VideoCard'
import Image from '../../reusableComponents/image/Image'


// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { DynamicContentSection } from '../../../pages/dynamicContentPage/DynamicContentSection'
import { References } from 'components/content/References'
import { getValue } from 'utils/contentUtils'
import {AccessCourse} from 'components/content/cards/AccessCourse';

import './MoodleDetail.scss'
import { useWebsiteConfig, useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'

const DetailCoursePage= ({ content,template }) => {
    const {
        headerText,
        title,
        subtitle,
        headerLogo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        contentSection,
        relatedVideos,
        relatedContent,
        courseExternalLink,
        bannerImage,
        veevaApprovalCode,
        references,
        subnavigation,
    } = content ? content.elements : {}
    let accessCourseDetailPageLabel = useWebsiteConfig()?.websiteConfig?.accessCourseDetailPageLabel;
    return (
        <section role='main' className='global_layout' data-layout-type='dynamic-content-page' id='modalDetail'>
                <section className='container'>
                    <div className='brand_logo_section'>

                    {headerLogo && (
                        <Image data={headerLogo} />
                    )}
                </div>
            </section>
            <MainAreaTitleSection
                title={title && title.value}
                description={headerText}
                category={subtitle && subtitle.value}
                backgroundImage={headerBackgroundImage}
                backgroundHexColour={headerBackgroundHexColour}
                fontHexColour={headerTitleHexColour}
            />
            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-2-left'>{subnavigation?.value && <SideNavigation element={getValue(subnavigation)} />}</section>
                        <section className='content_wrapper content_wrapper--columns-2-center'>
                            <div className='content_item'>
                                {contentSection?.values.map((section, index) => (
                                    <DynamicContentSection
                                        element={section}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                    />
                                ))}
                                {courseExternalLink?.value &&
                                    <div className="accessCourse">
                                        <AccessCourse onClickPath={courseExternalLink?.value } label= {accessCourseDetailPageLabel}/>
                                    </div>
                                }
                            </div>
                            <div className='content_item1'>
                                {relatedVideos && (
                                    <RelatedContent bgColor='#ffffff' headingText={relatedVideos?.value?.elements?.relatedVideosHeading?.value} fluid>
                                        <Grid wrapperStyle={{ marginTop: 40 }} itemsPerRow={{ sm: 1, md: 2, lg: 3 }} gap={{ sm: 20, md: 20, lg: 20 }}>
                                            {relatedVideos?.value?.elements?.relatedVideosVideoCards?.values.map((video) => {
                                                const { id, lastModified } = video || {}

                                                const { videoCardContent, videoCardHeading, videoCardImage,externalVideoLink:videoCardExternalLink, videoCardLink, videoCardSubheading, videoCardVideo } = video?.elements || {}
                                                return (
                                                    <VideoCard
                                                        key={id}
                                                        href={videoCardLink?.linkURL}
                                                        image={videoCardImage}
                                                        video={videoCardVideo}
                                                        heading={videoCardHeading?.value}
                                                        subHeading={videoCardSubheading?.value}
                                                        content={videoCardContent?.value?.text}
                                                        postDate={lastModified}
                                                        externalVideoLink={videoCardExternalLink}
                                                    />
                                                )
                                            })}
                                        </Grid>
                                    </RelatedContent>
                                )}
                            </div>
                        </section>
                        <div className='content_wrapper content_wrapper--columns-2-right'></div>
                    </div>
                    {relatedContent && <RelatedContent content={relatedContent} fluid />}
                    <div className="modal-references">
                        {references && <References references={references}  veevaApprovalCode={veevaApprovalCode}/>}
                    </div>
                </div>
            </section>
        </section>
    )
}

export default DetailCoursePage
