import React from 'react'
import dayjs from 'dayjs'

// config
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'
import { getValue } from 'utils/contentUtils'


// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import SideNavigation from '../../modules/sideNavigation/SideNavigation'

import Link from 'components/reusableComponents/link/Link';
import Image from 'components/reusableComponents/image/Image';

// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { AuthorSection } from 'components/content/article/AuthorSection'
import { References } from 'components/content/References'
import { DynamicArticleSection } from 'components/content/article/DynamicArticleSection'
import { PreviewIframe } from 'components/reusableComponents/preview-iframe/PreviewIframe'
import { ChaptersPagination } from 'modules/chaptersPagination/ChaptersPagination'

// styles
import '../../pages/dynamicArticlePage/DynamicArticlePage';
import './ThirdPartyPage.scss';

import clock from '../../assets/images/clock.png';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'


const ThirdPartyPage = ({ content,template }) => {
    const {
        title,
        subtitle,
        logo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        authors,
        date,
        articleSection,
        bannerImage,
        veevaApprovalCode,
        relatedContent,
        references,
        contentChapters,
        subnavigation,
        timeToRead,
        externalContentLink,
        externalContentImage,
        hidePreviewContent
    } = content.elements
    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const { tPublicationDate } = useWebsiteConfigObject()

    const formattedDate = date && date.value ? dayjs(date.value).format('D MMM YYYY') : null

    const dateStr = formattedDate ? `${tPublicationDate}, ${formattedDate}` : null

    const sectionElements = articleSection ? articleSection.values : null
    const chapter = contentChapters ? contentChapters.value : null

    const hasReferences = references && references.value

    const logoValue= logo?.value?.elements

    const timeReading = timeToRead.value;

    const externalContentLinkUrl = externalContentLink.linkURL;
   
    
    
      // need to create an external function to get the token
      const authObject = window.localStorage.getItem('persist:auth')
      if (!authObject) return false
      const auth = JSON.parse(authObject)
      const data = auth?.data
      const { token: _token } = JSON.parse(data) || {}
      const { token } = _token || {}
      //  end of function get token
   
    return (
        <section role='main' className='global_layout' data-layout-type='dynamic-article-page' id="third_party">
            {logoValue?.logo?.url && (
                <section className='brand_logo_section'>
                    <div className='container'>
                        <Link href={logoValue?.logoLink?.linkURL }>
                            <Image data={logoValue?.logo} />
                        </Link>
                    </div>
                </section>
             )}
            <section id="third_party-title">
            <MainAreaTitleSection
                title={title && title.value}
                category={subtitle && subtitle.value}
                backgroundHexColour={headerBackgroundHexColour}
                fontHexColour={headerTitleHexColour}
            />
            </section>
            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-3-left'>{subnavigation?.value && <SideNavigation element={getValue(subnavigation)} />}</section>
                        <section className='content_wrapper content_wrapper--columns-3-center'>
                            <div className='content_item'>
                                <div className="dates"><p className='date'>{dateStr}</p><div className="clock_space"><img src={clock} alt="clock" className="clock"></img></div> <p className="timeToRead">{timeReading}</p></div>
                                <AuthorSection authors={authors} icon={"iconUser"} />
                            </div>
                            <div className='content_item'>
                                {sectionElements &&
                                    sectionElements.map((section, index) => (
                                        <DynamicArticleSection
                                            element={section}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                        />
                                    ))}
                            </div>
                            <PreviewIframe
                            source={externalContentLinkUrl}
                            image= {externalContentImage}
                            queryString={token}
                            toggle = {hidePreviewContent}
                            />
                        </section>
                    </div>
                    {relatedContent && <RelatedContent content={relatedContent} fluid />}
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    <section className="container-references">
                        <References
                            references={references}
                            veevaApprovalCode={veevaApprovalCode}
                        />
                    </section>
                </div>
            </section>
        </section>
    )
}

export default ThirdPartyPage