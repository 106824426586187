import React, { useEffect } from 'react';
import { contentItemProptype } from 'propTypes/contentProptypes';
import { useState } from 'react';
import {CloseButton} from '../../shared/CloseButton';
import { getLocationOrigin,getUrlCountryPath } from 'utils/urlUtils';
import { getQuery } from '../../../api/AxiosQueries';
import { useWebsiteConfig } from 'websiteConfig/useWebsiteConfig';
import { useGoogleAnalytics } from '../../../hooks/useGoogleAnalytics';
import { AppConfig } from 'AppConfig';
import icon from '../../../assets/images/openPresentation.png';
import { useScreenWidth, SCREEN_WIDTH_MOBILE } from 'components/layout/ScreenWidthContext';
import { useSelector } from 'react-redux';
import '../../pageTemplates/Edetailer.scss';
import './ButtonModal.scss'


export const shouldDisplayFrame = (checkHeader) => {
    let header = checkHeader?.data?.code;
    let xframeOption
    if( checkHeader?.data?.data != undefined){
        xframeOption= checkHeader?.data?.data['x-frame-options']
    }
    if(xframeOption === "DENY" || header !== 'ok') {
        header = 'Not Ok';
    }
    return header;
}
 
const ButtonModal = ({contentScript, overlayImage, overlayTextButton, showPreview = false, decoration, pageTemplate}) => {
    let sourceIframe = contentScript;
    let image = overlayImage;
    const { data } = useSelector((state) => state.authentication);
    const { token } = data.token || {};
    const [isOpened, setIsOpened] = useState(false);
    const [iframeError, setIframeError] = useState(false);
    const [iframeMobile, setIframeMobile] = useState(false);
    const theLinkIsInvalid = useWebsiteConfig()?.websiteConfig?.tTheLinkIsInvalid;
    // const errorText = buttonGeneral?.websiteConfig?.externalContent404Error;
    // const errorMobileMessage = buttonGeneral?.websiteConfig.externalContentMobileMessage;
    const { currentWidthRanges } = useScreenWidth();
    const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);
    const checkFrameUrl = async () => {
        const checkHeader = await getQuery(`${AppConfig?.lamda?.checkSiteHead}&token=${token}&url=${sourceIframe}`);
        let headerchecking = shouldDisplayFrame(checkHeader);
        setIframeError(headerchecking !== 'ok');
    }

    const toggle = (e) => {
        e.preventDefault()
        setIsOpened( !isOpened );
        handleClick(token, sourceIframe);
        document.body.style['overflow-y'] = 'hidden';
    }

    const { trackEventWithContentTypeSource  } = useGoogleAnalytics()

    const handleClick = (itemId,source) => {
        trackEventWithContentTypeSource('contentScript', itemId, source)
    }
    const close= () =>{
        document.body.style['overflow-y'] = 'visible';
        setIsOpened(false);
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                close()
           }
         };
         window.addEventListener('keydown', handleEsc);
        checkFrameUrl();
    }, [sourceIframe]);

    let finalSrc = sourceIframe;
    if(sourceIframe.includes('?'))
    {     
        finalSrc=`${sourceIframe}&token=${token}&referalUrl=${getLocationOrigin()}/${getUrlCountryPath()}`;
    }
    else
    {        
        finalSrc=`${sourceIframe}?token=${token}&referalUrl=${getLocationOrigin()}/${getUrlCountryPath()}`;
    }

    return ( 
        <div className = "edetailer">
            { decoration !== 'link' ? <>
                { image && <div className = "iframe-preview">
                    {
                        <div className = "iframe-preview--success">
                            <section className="iframe-image-sucess">
                                { !showPreview && <img src = {image} /> }
                                { showPreview && <iframe className="VeevaIframe" id="iframeEdetailer" sandbox="allow-same-origin allow-forms allow-scripts  allow-modals allow-popups  allow-presentation allow-top-navigation allow-popups-to-escape-sandbox" src={finalSrc} width="100%" height="100%" title="Veeva Player" scrolling="yes" x-frame option="allow"/> }
                            </section>
                        </div>
                    }
                </div> }
                <button type='button' onClick = { toggle } className = "iframe-button" id="iframeButton"disabled = {iframeError} > {overlayTextButton}<img className="imagebutton" src={icon} alt="open presentation" id="imagebutton"></img></button>
                </> : <div><a className = {iframeError ? 'cursor-default' : ''} onClick = {(e)=>{if(!iframeError){
                toggle(e);
                }}} href = "#"> {overlayTextButton} <span className={[iframeError ?'tooltiptext-linkNotAvailable tooltiptextShowMessage':'tooltiptext-linkNotAvailable' ].join(' ')} id="linkNotAvailable">{theLinkIsInvalid}</span></a> </div>}
                { isOpened && (
                <div className="overlay overlay-iframe showHideClassName" id="overlay" onClick={()=>{ close();
            }} >
            <section role="main" className="veeva-page-component edetailer-modal" id="edetailerModal">
                <div className="taskbar">
                <div className="button-frame">
                    <CloseButton
                        onClick={()=>{ close();
                        }}
                        
                    />
                </div>
                   
               
                </div>
                <section  className="veeva-page-component section">
                    <div className="veeva-container" >
                        <div className="veeva-aspect-ratio veeva-aspect-ratio--dynamic-height">
                        <div className="veeva-player edetailer-player" id="engage">
                            <iframe className="VeevaIframe" id="iframeEdetailer" sandbox="allow-same-origin allow-modals allow-forms allow-popups allow-scripts allow-presentation allow-top-navigation allow-popups-to-escape-sandbox" src={finalSrc} width="100%" height="100%" title="Veeva Player" scrolling="yes" x-frame option="allow"/>
                        </div>
                        </div>
                    </div>
                </section>
            </section>
            </div>
            )} 
        </div>
    );
};

ButtonModal.propTypes = {
    content: contentItemProptype,
  };
 export { ButtonModal }; 