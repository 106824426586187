import React from 'react';
import PropTypes from 'prop-types';
import {
  contentElementProptype,
  contentItemProptype,
} from 'propTypes/contentProptypes';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { References } from 'components/content/References';
import './AvailableTreatmentsPage.scss';
import { PromoBanner } from 'components/layout/PromoBanner';
import { BannerImage } from 'components/content/BannerImage';
import ContentCard from 'modules/contentCard/ContentCard';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { getSelectionValue } from 'utils/contentUtils';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';
import { useSelector } from 'react-redux';


const ContentRow = ({
  sectionTitle,
  logoCards,
}) => {
  const { tLearnMore } = useWebsiteConfigObject();
  const { trackEventWithContentType } = useGoogleAnalytics();
  const { redesign } = useSelector(state => state.ui);
  if(redesign){
    return  (
      <div
        className="category-row"
      >
        {logoCards?.length > 0 && 
          <div className="category-row-left">
            <div className='categorie-title'>
            {
              sectionTitle && (
                <h3 className="category-name">
                  {sectionTitle}
                </h3>
              )
            }
            </div>
            <div className='categorie-span'>
              <h3></h3>
            </div>
          
          </div>
        }
        <div className="category-row-right">
          {
            logoCards?.length > 0 && logoCards?.map((cardItem) => {
              const { elements } = cardItem || {}
    
              if (!elements) return null
              
              let cardType = getSelectionValue(elements?.cardType);
              if (cardType) {
                return (
                  <div
                    key={cardItem.id}
                    onClick={() => trackEventWithContentType('product-tile', elements.title?.value)}
                    className="list-content-page-card-wrapper"
                  >
                    <ContentCard
                      key={cardItem?.id}
                      theme='primary'
                      category={cardType === "IconCard" ? elements?.category?.value : ""}
                      brandLogo={cardType === "ImageCard" ? elements?.logoImage : ""}
                      href={elements?.link?.linkURL}
                      content={elements?.text}
                      linkText={tLearnMore}
                      clickable
                      alternateBg
                    />
                  </div>
                )
              }
              else {
                return (
                  <div
                    key={cardItem.id}
                    onClick={() => trackEventWithContentType('product-tile', elements.title?.value)}
                    className="list-content-page-card-wrapper"
                  >
                    <ContentCard
                      key={cardItem?.id}
                      theme='primary'
                      category={elements?.category?.value}
                      brandLogo={elements?.logoImage}
                      href={elements?.link?.linkURL}
                      content={elements?.text}
                      linkText={tLearnMore}
                      clickable
                      alternateBg
                    />
                  </div>
                )
              }
    
            })
          }
        </div>
      </div>
    )
  }
  return  (
    <div
      className="category-row"
    >
      <div className="category-row-left">
        {
          sectionTitle && (
            <h3 className="category-name">
              {sectionTitle}
            </h3>
          )
        }
      </div>
      <div className="category-row-right">
        {
          logoCards?.length > 0 && logoCards?.map((cardItem) => {
            const { elements } = cardItem || {}
  
            if (!elements) return null

            return (
              <div
                key={cardItem.id}
                onClick={() => trackEventWithContentType('product-tile', elements.title?.value)}
                className="list-content-page-card-wrapper"
              >
                <ContentCard
                  key={cardItem?.id}
                  theme='primary'
                  category={elements?.category?.value}
                  brandLogo={elements?.logoImage}
                  href={elements?.link?.linkURL}
                  content={elements?.text}
                  linkText={tLearnMore}
                  clickable
                  alternateBg
                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

ContentRow.propTypes = {
  sectionTitle: PropTypes.string,
  logoCards: PropTypes.arrayOf(contentElementProptype),
};



const AvailableTreatmentsPage = ({
  content,
}) => {

  const {
    title,
    headerText,
    subtitle,
    section1Title,
    section2Title,
    logoCards1,
    logoCards2,
    cards,
    callout,
    bannerImage,
    references,
  } = content.elements;

  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;


  // to remove these line of code when content is ready
  const section1TitleValue = section1Title && section1Title.value;
  const logoCards1Value = logoCards1 && logoCards1.values;

  const section2TitleValue = section2Title && section2Title.value;
  const logoCards2Value = logoCards2 && logoCards2.values;

  return (
    <section role="main" className="available-page-template">
      <MainAreaTitleSection
        title={title && title.value}
        description={headerText}
        category={subtitle && subtitle.value}
      />
      <section className="content-section">
        <div className="container">
          {/* this section need to be remove when all of the market change their content to use the dynamic one above */}
          
              <ContentRow
                sectionTitle={section1TitleValue}
                logoCards={logoCards1Value}
              />
              <ContentRow
                sectionTitle={section2TitleValue}
                logoCards={logoCards2Value}
              />
            
          {
            cards?.values?.map( (card, index) => <ContentRow
              key={index}
              sectionTitle={card.elements?.title?.value}
              logoCards={card.elements?.logoCards?.values}
            />)
          }
          <PromoBanner
            content={callout}
          />
          {bannerImage && <BannerImage image={bannerImage} />}
          {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
          <References
            references={references}
          />
        </div>
      </section>
    </section>
  );
};


AvailableTreatmentsPage.propTypes = {
  content: contentItemProptype,
};


export { AvailableTreatmentsPage };
