import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import Image from 'components/reusableComponents/image/Image';
import { HeaderBannerImageBg } from 'components/content/eapc/HeaderBannerImageBg';
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection';
import { DynamicArticleSection } from 'components/content/article/DynamicArticleSection';
import RichText from 'components/reusableComponents/richText/RichText';
import TextAndImagePanel from 'modules/textAndImagePanel/TextAndImagePanel';
import { BannerImageBackground } from 'components/content/eapc/BannerImageBackground';
import { References } from 'components/content/References';
import RelatedContent from 'modules/relatedContent/RelatedContent';
import EapcImagesPanelPdf from 'components/content/eapc/EapcImagesPanelPdf';
import { getUrlCountryPath } from 'utils/urlUtils';

import './EventThankYouPage.scss';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';
import VeevaPortalViewer from 'modules/veevaPortalViewer/VeevaPortalViewer';

const EventThankYouPage = ({ content }) => {
  const {
    title,
    subtitle,
    logo,
    headerBackgroundImage,
    headerTitleHexColour,
    headerBackgroundHexColour,
    articleSection,
    bannerImage,
    relatedContent,
    references,
    externalContentLink,
    externalContentImage,
    hidePreviewContent,
    formBuilder,
    currentLocale,
    alternateLinks,
    enableGeoipLocator,
    ipStackApiKey,
    description,
    featuredContent,
    brandColours,
    keySpeakersLabel,
    headerText,
    footerImageBanner,
    veevaApprovalCode,
    eventPicker,
    redirectUrl,
    eventId,
  } = content.elements;

  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
  //colours
  const primaryColor = brandColours?.value?.elements?.primary;
  const secondaryColor = brandColours?.value.elements?.secondary;
  const footerBannerBackground =
    brandColours?.value.elements?.footerBannerBackground;
  const footerBannerBackgroundStyle = {
    backgroundColor: footerBannerBackground?.value || '',
  };
  const footerBannerWithBackground = footerBannerBackground?.value
    ? true
    : false;

  const ctaButtonsBackground =
    brandColours?.value.elements?.ctaButtonsBackground;

  const sectionElements = articleSection ? articleSection.values : null;
  const history = useHistory();
  const [cookies, setCookie] = useCookies([
    'analyticsCookiesAccepted',
    'eventRegistered',
  ]);
  const eventSession = window.sessionStorage.getItem('events');
  useEffect(() => {
    const DAYS_COOKIES_VALID = 28;
    const daysInSeconds = (days) => days * 86400;
    const COOKIE_OPTIONS = {
      path: `/${getUrlCountryPath()}`,
      maxAge: daysInSeconds(DAYS_COOKIES_VALID),
    };

    if (!eventSession) {
      if (!cookies.eventRegistered) {
        history.push(redirectUrl?.value);
      } else {
        let eventsRegisteredCookies = cookies.eventRegistered;
        if (!eventsRegisteredCookies.includes(eventId?.value)) {
          history.push(redirectUrl?.value);
        }
      }
    } else {
      let eventsRegistered = JSON.parse(eventSession);
      if (!eventsRegistered.includes(eventId?.value)) {
        history.push(redirectUrl?.value);
      } else {
        if (cookies.analyticsCookiesAccepted === 'true') {
          if (cookies.eventRegistered) {
            let oldEventsRegisteredCookies = cookies.eventRegistered;
            if (!oldEventsRegisteredCookies.includes(eventId?.value)) {
              oldEventsRegisteredCookies.push(eventId?.value);
              setCookie('eventRegistered', eventsRegistered, COOKIE_OPTIONS);
            }
          } else {
            let newEventsRegisteredCookies = new Array();
            newEventsRegisteredCookies.push(eventId?.value);
            setCookie(
              'eventRegistered',
              newEventsRegisteredCookies,
              COOKIE_OPTIONS
            );
          }
        }
      }
    }
  }, []);

  return (
    <section
      role='main'
      className='global_layout eapc-thank-you-page'
      data-layout-type='dynamic-article-page'
      id='eapc'
    >
      <div className='eapc-logo'>
        <Image data={logo} />
      </div>
      <section className='bannerImage'>
        <HeaderBannerImageBg image={bannerImage} />
      </section>
      <section
        className={`eapc-main-title-section${
          headerText?.value ? '' : ' no-headline'
        }`}
      >
        <MainAreaTitleSection
          title={title && title.value}
          headline={headerText}
          backgroundHexColour={primaryColor}
          fontHexColour={secondaryColor}
        />
      </section>
      <section>
        <div className='container'>
          <section className='container_inner'>
            <div className='content_item '>
              {sectionElements &&
                sectionElements.map((section, index) => (
                  <DynamicArticleSection
                    element={section}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  />
                ))}
              {description && (
                <div className='richtext-description'>
                  <RichText data={description} />
                </div>
              )}
              {featuredContent?.values &&
                featuredContent?.values.map((content, index) => {
                  if (
                    content?.type == 'Module - Event Panel Text and Image'
                  ) {
                    return (
                      <TextAndImagePanel
                        content={content}
                        ctaBtnBackground={ctaButtonsBackground}
                        key={index}
                      />
                    );
                  }
                  if(content?.type === "Module - Veeva Portals Viewer") {
                    return <VeevaPortalViewer element={content} />
                  }
                  if (content?.type == 'Module - Image With Pdf Download') {
                    return <EapcImagesPanelPdf content={content} key={index} />;
                  }
                })}
            </div>
          </section>
        </div>
      </section>

      <section
        className={`footer-banner-container${
          footerBannerWithBackground ? ' with-background' : ''
        }`}
        style={footerBannerBackgroundStyle}
      >
        <div className='container'>
          <section className='footer-bannerImage'>
            <BannerImageBackground image={footerImageBanner} />
          </section>
        </div>
      </section>
      <section>
        <div className='container'>
        {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
          <section className='container-references'>
            <References
              references={references}
              veevaApprovalCode={veevaApprovalCode}
            />
          </section>
        </div>
      </section>
    </section>
  );
};

export default EventThankYouPage;
