import React from 'react'
import { getAssetUrl } from 'api/acousticApi'
import { getColourHashValue, getValue, getValues } from 'utils/contentUtils'

// modules
import RelatedContent from 'modules/relatedContent/RelatedContent'
import LinkPanel from '../../modules/linkPanel/LinkPanel'
import SideNavigation from '../../modules/sideNavigation/SideNavigation'

// components
import { VideoSelector } from 'components/content/mainPromoPage/VideoSelector'
import { References } from 'components/content/References'
import RichText from 'components/reusableComponents/richText/RichText'
import Video from 'components/reusableComponents/video/Video'
import Image from 'components/reusableComponents/image/Image'
import { BannerImage } from 'components/content/BannerImage'
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
  } from 'components/layout/ScreenWidthContext';

// styles
import './MainPagePromo.scss'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'

/*
  Main page (promo)
  Figma design here https://www.figma.com/file/rzkpaL4Xk0TFxbXRxlNhtN/Global-Navigation-V5.0?node-id=42%3A0
  2.2  Moventig - Available treatments pages
*/

const MainPagePromoHeaderMedia = ({ headerVideo, headerImage, videoPoster, videoPosterImage,externalVideoLink }) => {
    if (headerVideo && headerVideo.url) {
        return (
            <div className='media-container'>
                <Video element={headerVideo} externalVideo={externalVideoLink} videoPoster={videoPoster} videoPosterImage={videoPosterImage} />
            </div>
        )
    }
    if (headerImage && headerImage.url) {
        const imgUrl = getAssetUrl(headerImage)
        return (
            <div className='media-container'>
                <figure className='image'>
                    <img src={imgUrl} alt='' />
                </figure>
            </div>
        )
    }
    return null
}

const MainPagePromo = ({ content }) => {
    const {
        primaryColourHashNumber,
        secondaryColourHashNumber,
        primaryLightColourHashNumber,
        title,
        headerText,
        headerVideo,
        videoPoster,
        videoPosterImage,
        headerImage,
        subHeaderColumn1,
        subHeaderColumn2,
        section1Title,
        expertVideoSelector,
        section2Title,
        section2LeftColumnText,
        section2LeftColumnTitle,
        section2ParagraphCards,
        carousel,
        references,
        medicationLogo,
        subnavigation,
        bannerImage,
        relatedContent,
        subContentColumn1,
        subContentColumn2,
        externalVideoLink
    } = content.elements

    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const primaryColour = getColourHashValue(primaryColourHashNumber)
    const secondaryColour = getColourHashValue(secondaryColourHashNumber)
    const primaryLightColour = getColourHashValue(primaryLightColourHashNumber)
    const videos = getValues(expertVideoSelector)
    const hasVideos = videos && videos.length
    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
    const section2LinkElements = getValues(section2ParagraphCards)

    const linkPanelElements = []
    section2LinkElements.forEach(element => {
        linkPanelElements.push(element?.elements)
    });
    const settings = {
        infinite: carousel?.value?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 2,
        autoplay: isSmall ? false  : carousel?.value?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: carousel?.value?.elements?.automationInterval?.value ? parseFloat(carousel?.value?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }

    return (
        <section role='main' className='global_layout main-page-promo' data-layout-type='main-page-promo'>
            <h1 className="main-page-promo-hidden">{title.value}</h1>
            <section className='brand_logo_section'>
                <div className='container'>
                    <Image data={medicationLogo} />
                </div>
            </section>

            <section className='main-page-promo-header-section' style={{ backgroundColor: primaryColour }}>
                <div className='container'>
                    <div className='main-page-promo-header'>
                        <div className='header-text'>
                            <RichText data={headerText} />
                        </div>
                        <MainPagePromoHeaderMedia headerVideo={headerVideo} externalVideoLink={externalVideoLink} headerImage={headerImage} videoPoster={videoPoster} videoPosterImage={videoPosterImage} />
                    </div>
                </div>
            </section>

            { (subHeaderColumn1?.value || subHeaderColumn2?.value) && <section className='main-page-promo-subheader-section'>
                <div className='container'>
                    <div className='main-page-promo-subheader'>
                        <div className='column'>
                            <RichText data={subHeaderColumn1} />
                        </div>
                        <div className='column'>
                            <RichText data={subHeaderColumn2} />
                        </div>
                    </div>
                </div>
            </section> }

            {hasVideos && (
                <section className='main-page-promo-video-selector-section'>
                    <div className='container'>
                        <div className='main-page-promo-video-selector'>
                            <h3 className='promo-video-selector-h3'>{getValue(section1Title)}</h3>
                            <VideoSelector primaryColour={primaryColour} primaryLightColour={primaryLightColour} videoElements={videos} />
                        </div>
                    </div>
                </section>
            )}

            <section>
                <div className='container'>
                    <div className='container_inner'>
                        <section className='content_wrapper content_wrapper--columns-2-left'>
                            {subnavigation?.value && <SideNavigation element={getValue(subnavigation)} colour={getColourHashValue(primaryColourHashNumber)} />}
                        </section>
                        <section className='content_wrapper content_wrapper--columns-2-center content_wrapper--columns-2-center--stretch'>
                            <div className='content_item'>
                                <h3 className='promo-treatment-h3'>{getValue(section2Title)}</h3>
                                <LinkPanel linkColour={secondaryColour} introTitleElement={section2LeftColumnTitle} introTextElement={section2LeftColumnText} linkElements={linkPanelElements} />
                            </div>
                            <div className='content_item'>
                                {carousel && (
                                    <RelatedContent
                                        content={carousel}
                                        componentType='cards'
                                        bgColor='#ffffff'
                                        carouselSettings={settings}
                                        cardTheme='secondary'
                                        alternateCardBg={true}
                                        fluid
                                        removeTopPadding
                                    />
                                )}
                            </div>
                        </section>
                        <div className='content_wrapper content_wrapper--columns-2-right content_wrapper--columns-2-right--shrink'></div>
                    </div>
                    {relatedContent && <RelatedContent content={relatedContent} cardTheme='secondary' fluid />}
                    {bannerImage && <BannerImage image={bannerImage} />}
                </div>
            </section>
            {subContentColumn1?.value != undefined || subContentColumn2?.value != undefined 
            ? <section className='main-page-promo-subheader-section footer-subheader-section'>
                <div className='container'>
                    <div className='main-page-promo-subheader'>
                        <div className='column'>
                            <RichText data={subContentColumn1} />
                        </div>
                        <div className='column'>
                            <RichText data={subContentColumn2} />
                        </div>
                    </div>
                </div>
            </section> : "" }
            <section>
                <div className='container'>
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    {references && <References references={references} />}
                </div>
            </section>
        </section>
    )
}

export default MainPagePromo
