import React, { useEffect, useRef } from 'react'
import { contentItemProptype } from 'propTypes/contentProptypes'
import dayjs from 'dayjs'

import { useWebsiteConfig, useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'

// modules
import Header from 'modules/header/Header'
import Subnavigation from '../../modules/subNavigation/SubNavigation'
import Content from 'modules/content/Content'
import Link from 'components/reusableComponents/link/Link';
import Image from 'components/reusableComponents/image/Image';
import RelatedContent from 'modules/relatedContent/RelatedContent';

// components
import { References } from 'components/content/References'
import Anchors from 'modules/contentAnchors/ContentAnchors'
import {ButtonModal} from '../reusableComponents/buttonModal/ButtonModal';
import { getValue } from 'utils/contentUtils';
import {
    useScreenWidth,
    SCREEN_WIDTH_SMALL,
    SCREEN_WIDTH_MOBILE,
  } from 'components/layout/ScreenWidthContext';

// styles
import '../../pages/brandArticlePage/BrandArticlePage';
import './Edetailer.scss';
import { Features } from 'components/shared/feature/Features'
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance'


const EdetailerPage = ({ content,
    template, }) => {

    const {      
        logo,
        brandColours,
        title,
        header,
        subnavigation,
        content: contentSection,
        veevaApprovalCode,
        references,
        bodyDescription,
        contentScript,
        relatedContent,
        publicationDate,
        timeToRead,
        eDetailerImage,
        
        
    } = content.elements
    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;
    const { currentWidthRanges } = useScreenWidth();
    const isSmall = currentWidthRanges.includes(SCREEN_WIDTH_SMALL);
    const isMobile = currentWidthRanges.includes(SCREEN_WIDTH_MOBILE);
    useEffect(() => {
        const contentDiv = document.getElementsByClassName('brand-article-page__content-section-inner')[0];
        if(contentDiv){
            isMobile ? contentDiv?.insertBefore(contentDiv?.lastChild, contentDiv?.firstChild) : (contentDiv?.firstChild.className === "brand-article-page__content-titles" && contentDiv.lastChild.after(contentDiv?.firstChild))
        }
    }, [isMobile])
    const stickyMenuRef = useRef();


    const settings = {
        infinite: relatedContent?.value?.elements?.automateCarousel?.value ? true : false,
        dots: true,
        arrows: true,
        slidesToShow: 3,
        autoplay: isSmall ? false  : relatedContent?.value?.elements?.automateCarousel?.value ? true : false,
        autoplaySpeed: relatedContent?.value?.elements?.automationInterval?.value ? parseFloat(relatedContent?.value?.elements?.automationInterval?.value) * 1000 : 2000,
        responsive: [
            {
                breakpoint: 1023,
                settings: { slidesToShow: 1, arrows: false },
            },
        ],
    }

    const { primary } = brandColours?.value?.elements || {}

    const { tPublicationDate } = useWebsiteConfigObject()

    const formattedDate = publicationDate && publicationDate.value ? dayjs(publicationDate.value).format('D MMM YYYY') : null

    const dateStr = formattedDate ? `${tPublicationDate}, ${formattedDate}` : null

    const readingTime = timeToRead?.value;

    const logoValue= logo?.value?.elements;
    
    // need to create an external function to get the token
    const authObject = window.localStorage.getItem('persist:auth')
    if (!authObject) return false
    const auth = JSON.parse(authObject)
    const data = auth?.data
    const { token: _token } = JSON.parse(data) || {}
    const { token } = _token || {}
    //  end of function get token
    return (
        <main className={`brand-article-page ${contentSection?.values && ' anchorsFix'}`} id="edetailer">
             <section className='brand_logo_section'>
                <div className='container'>
                    {logoValue && (
                        <Link href={logoValue?.logoLink?.linkURL }>
                            <Image data={logoValue?.logo?.url} />
                        </Link>
                    )}
                </div>
            </section>
            <section className='brand-article-page__header'>
                <Header title={title} content={header} backgroundColour={primary} />
            </section>           
            <div className="list-content-page">
                <div className="column-left">
                    <Subnavigation element={getValue(subnavigation)}
                    />
                    <section className="main-page-section">
                    {dateStr?.values &&
                        <div className='content_item content-item--date'>
                                <p className='date'>{dateStr}</p> 
                                <p className='readingTime'>{readingTime}</p>
                        </div>
                    }
                        <div className='brand-article-page__content'>                            
                            {contentSection?.values?.map((section, index) => (
                                <Content content={section} colour={primary} key={index} />
                            ))}
                        </div>
                        <div ref={stickyMenuRef} className='brand-article-page__content-titles'>
                            {contentSection?.values && (
                                <Anchors elements={contentSection.values} containerRef={stickyMenuRef}/>
                            )}                            
                        </div>
                        <Features
                        pageName="Layout A - Landing page"
                        contentType="Component - Moodle"
                        > 
                            <div>Test feature</div>
                        </Features> 
                     </section>
                </div>
            </div>         
            <section className="content-section">
                {relatedContent && (
                    <RelatedContent 
                        content={relatedContent} 
                        componentType='cards' 
                        bgColor='#FAF6F4'
                        cardTheme='primary'
                        // alternateCardBg={true}
                        carouselSettings={settings}
                        fluid
                    />
                )}
            </section>
            <section  className="container-references">
            {
                adverseEventsComplianceText?.value && (
                    <div className="container">
                        {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
                    </div>
                )}
            {
                references && (
                    <div className="container">
                    <References
                        references={references}
                        veevaApprovalCode={veevaApprovalCode}
                    />
                    </div>
                )}
            </section>
        </main>
    )
}

EdetailerPage.propTypes = {
    content: contentItemProptype,
}

export default EdetailerPage
