import React from 'react';
import { contentElementProptype } from 'propTypes/contentProptypes';
import KKLogoOrange from 'assets/images/KK_logo_orange.png';
import './ApprovalCode.scss';

// Component that renders the 'KK Orange Logo' along with the 'Veeva approval code' (string) above the references block.
const ApprovalCode = ({
  code,
}) => {
  if (!code || !code.value) { return null; }

  return (
    <div className="approval-code-component">
      <img
        src={KKLogoOrange}
        alt="Kyora Kirin Logo"
        className="approval-code-component__logo"
      />
      <span className="approval-code-component__code">{ code.value }</span>
    </div>
  );
};

ApprovalCode.propTypes = {
  code: contentElementProptype,
};

export {
  ApprovalCode,
};
