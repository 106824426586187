import React, { useEffect, useState, useRef } from "react";
import { usePageContent } from "api/acousticApi/ContentHooks";
import { useHistory } from 'react-router-dom';

// layouts
import Grid from "layouts/grid/Grid";

// modules
import RelatedContent from "modules/relatedContent/RelatedContent";
import SideNavigation from "../../../modules/sideNavigation/SideNavigation";
import VideoCard from "modules/videoCard/VideoCard";
import Image from "../../reusableComponents/image/Image";

// components
import { MainAreaTitleSection } from "components/layout/MainAreaTitleSection";
import { DynamicContentSection } from "../../../pages/dynamicContentPage/DynamicContentSection";
import { References } from "components/content/References";
import { getValue } from "utils/contentUtils";
import { convertHTMLToText } from "utils/stringUtils";
import { AccessCourse } from "components/content/cards/AccessCourse";
import { PromoBanner } from "components/layout/PromoBanner";
import FormBuilder from 'modules/formBuilder/FormBuilder';

import { useWebsiteConfig } from "websiteConfig/useWebsiteConfig";
import { WebinarBanner } from "./WebinarBanner";
import WebinarSpeakers from "./WebinarSpeakers";

import { WebinarSignUpForm } from "./WebinarSignUpForm";

import {
  ChevronRight,
  Calender,
  Location,
  Clock,
  Tick,
  GmailLogo,
  OutlookLogo,
  Download,
} from "components/shared/SvgIcons";
import { webinarSignUp } from "api/dynamoApi";
import { useSelector } from "react-redux";
import { getCourses } from "api/dynamoApi";
import { webinarSignUpDataCapture } from 'api/dataCaptureApi';

//icons

import { Loader, TINY_LOADER } from "components/shared/Loader";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";

//helpers

import {
  getWebinarDate,
  getWebinarTime,
  getWebinarTime1,
  getWebinarDateWithLocal,
  getWebinarDate1,
  getCalendarUrl
} from "./webinarHelpers";
import { searchQuery } from "api/acousticApi";
import { useQuery } from "react-query";
import { getUrlCountryData } from "utils/libraryUtils";

import "./WebinarDetailPage.scss";
import { Description } from "@material-ui/icons";
import AdverseEventsCompliance from "components/content/AdverseEventsCompliance";

const WebinarDetailPage = ({ content, template }) => {
  const {
    title,
    subtitle,
    headerLogo,
    headerBackgroundImage,
    headerTitleHexColour,
    headerBackgroundHexColour,
    contentAccess,
    contentSection,
    relatedVideos,
    relatedContent,
    courseExternalLink,
    veevaApprovalCode,
    references,
    subnavigation,
    webinarDate,
    keynoteSpeakers,
    webinarTime,
    relatedWebinars,
    enableDataCapture,
    callout,
    webinarType,
    webinarExternalId,
    webinarUrl,
    webinarProvider,
    submissionType,
    relatedContentLabel,
    displayDefaultHeaderText,
    webinarEndDate,
    joinButtonPosition,
    webinarDateText,
    webinarEndDateText,
  } = content ? content.elements : {};
  const { id } = content;
  const { description } = content;
  const { selection: joinPosition } = joinButtonPosition?.value || {};
  const { redesign } = useSelector(state => state.ui);

  const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;

  const {
    accessCourseDetailPageLabel,
    webinarsKeynoteSpeakersLabel,
    webinarSignUpLabel,
    webinarRegisterTitle,
    relatedWebinarsLabel,
    webinarScheduleLabel,
    joinWebinarLabel,
    webinarRegistrationSuccess,
    webinarRegistrationFailure,
    webinarRegistrationSuccessCloseButtonLabel,
  } = useWebsiteConfig()?.websiteConfig;

  const { libraryId } = getUrlCountryData();

  const routesSearchParams = {
    q: "*:*",
    fl: "name,string1,document:[json],id,classification,type,status",
    fq: `type:("Component – Calendar Event Type Map")`,
    rows: 10000,
    start: 0,
  };

  const params = new URLSearchParams(routesSearchParams);

  params.append("fq", `libraryId:("${libraryId}")`);

  const test = () => searchQuery(params);
  const calenderQuery = useQuery(
    "calender-query",
    test,
    { staleTime: Infinity } // only request the api routes once
  );

  /**
   * calenderList is the calender component
   */
  let calendarId = [];
  let calenderList = calenderQuery?.data;
  calenderList?.data?.documents?.map((calendar) =>
    calendar?.document?.elements?.eventType?.value?.selection == "Webinar"
      ? (calendarId = calendar?.id)
      : console.log("bye")
  );

  let calendarText = usePageContent(calendarId);

  let bannerText = webinarScheduleLabel;
  if (bannerText && webinarDate?.value) {
    let date = getWebinarDateWithLocal(webinarDate);
    let time = getWebinarTime(webinarDate);
    bannerText = bannerText.replace("{date}", date);
    bannerText = bannerText.replace("{time}", time);
  }

  const [displayRegBtn, setDisplayRegBtn] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [hasDataCaptureForm, setHasDataCaptureForm] = useState(false);
  const [isWebinarActive, setIsWebinarActive] = useState(false);
  const [enableJoinBtn, seteEnableJoinBtn] = useState(false);
  const [webinarOver, seteWebinarOver] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const { trackWebinarRegistration, trackJoinWebinarButton, trackAddwebinarToCalender } = useGoogleAnalytics();
  const history = useHistory();

  const calenderRef = useRef(null);
  const registeredMessageRef = useRef(null);

  const { data, status } = useSelector((state) => state.authentication);
  const { user } = data || {};
  const { loggedIn } = status || {};
  const userId = user.userId;
  const accessLevel = contentAccess?.value?.selection
  const { token } = data?.token;

  useEffect(() => {
    if(accessLevel === "b" || accessLevel === "a") {
      if(!loggedIn){
        setDisplayRegBtn(false);
      }
    }
  }, [loggedIn]);
  useEffect(() => {
    let todayDateLocal = new Date().toLocaleDateString();
    let webinarDateLocal = new Date(webinarDate?.value).toLocaleDateString();
    let webinarDateLocalEnd = new Date(webinarEndDate?.value).toLocaleDateString();
    let todayDateLocalStamp = (new Date(new Date().toLocaleDateString("en-ZA"))).getTime();
    let webinarDateLocalStamp = (new Date(new Date(webinarDate?.value).toLocaleDateString("en-ZA"))).getTime();
    let webinarDateLocalEndStamp = (new Date(new Date(webinarEndDate?.value).toLocaleDateString("en-ZA"))).getTime();
    if (todayDateLocal === webinarDateLocal) {
      setIsWebinarActive(true);
      if((accessLevel === "b" || accessLevel === "a") && enableDataCapture?.value) {
        if(!loggedIn){
          seteEnableJoinBtn(true);
        }
      }
    }
    if(webinarEndDate?.value){
      if (todayDateLocalStamp > webinarDateLocalEndStamp) {
        seteWebinarOver(true);
      }
    } else {
      if (todayDateLocalStamp > webinarDateLocalStamp && todayDateLocal !== webinarDateLocal) {
        seteWebinarOver(true);
      }
    }
    if(webinarEndDate?.value){
      if(todayDateLocalStamp > webinarDateLocalStamp && webinarDateLocalEnd === todayDateLocal){
        setIsWebinarActive(true);
        if((accessLevel === "b" || accessLevel === "a") && enableDataCapture?.value) {
          if(!loggedIn){
            seteEnableJoinBtn(true);
          }
        }
      }
      if(todayDateLocalStamp > webinarDateLocalStamp && webinarDateLocalEndStamp > todayDateLocalStamp){
        setIsWebinarActive(true);
        if((accessLevel === "b" || accessLevel === "a") && enableDataCapture?.value) {
          if(!loggedIn){
            seteEnableJoinBtn(true);
          }
        }
      }
    }

    contentSection?.values.filter((section) => {
      if(section?.type === "Component – Form Builder") {
        setHasDataCaptureForm(true);
      }
    })
  }, []);
  
  if (!isFetching) {
    setIsFetching(true);
    let type = "webinar"; 
    getCourses({ userId, token, type })
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            response.data.filter((item) => {
              if(item?.data?.externalId){
                const webinarIds = item?.data?.externalId.split("|");
                if(webinarIds && webinarIds.length > 0) {
                  if (webinarIds[0] === id) {
                    if(enableDataCapture?.value){
                      setHasRegistered(true);
                    }
                  }
                }
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleConfirmation = () => {
    setIsSubmitting(true);

    let body = {};
    body["userId"] = userId ? userId : "";
    body["provider"] = webinarProvider?.value;
    body["type"] = "webinar";
    let data = {};
    if(webinarExternalId?.value){
      data = {
        externalId: id + "|"+ webinarExternalId?.value,
        name: title?.value,
        url: webinarUrl?.value,
        registration: new Date().toISOString(),
      };
    } else {
      data = {
        externalId: id,
        name: title?.value,
        url: webinarUrl?.value,
        registration: new Date().toISOString(),
      };
    }
    body["data"] = data;
    webinarSignUp({ body, token })
      .then((response) => {
        if (response.data.status === 200) {
          setIsSubmitting(false);
          setHasRegistered(true);
          setRegistrationSuccess(true);
          trackWebinarRegistration(
            userId,
            webinarExternalId?.value,
            title?.value
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        // trackWebinarRegistration(userId,webinarExternalId?.value,title?.value)
        setRegistrationError(true);
      });
  };

  useEffect(() => {
    setTimeout(()=>{
      if(hasRegistered && registeredMessageRef.current) {
        window.scrollTo({
          top: registeredMessageRef.current.offsetTop - 500,
          behavior: 'smooth'
        });
      }
    },500)
  },[hasRegistered])

  const convertDate = (date) => {
    let event = new Date(date).toISOString();
    let eventDate = event.split("T")[0];
    eventDate = eventDate.split("-");
    eventDate = eventDate.join("");
    let eventTime = event.split("T")[1];
    eventTime = eventTime.split(":");
    eventTime = eventTime.join("");
    eventTime = eventTime.split(".")[0];
    let eventDateTime = eventDate + "T" + eventTime;
    return eventDateTime;
  };

  let endDate = new Date(webinarDate?.value);
  endDate.setHours(endDate.getHours() + 4);
  let endDateIso = new Date(endDate).toISOString();

  let webinarDateTextValue = new Date(webinarDateText?.value);
  let webinarEndDateTextValue = new Date(webinarEndDateText?.value);
  let webinarTimeLocal = "";
  let webinarEndTimeLocal = "";
  if (webinarDateText?.value) {
    webinarTimeLocal = new Date(webinarDateText?.value).toLocaleString('en-GB', {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
    });
  } else if (webinarDate?.value) {
    webinarTimeLocal = new Date(webinarDate?.value).toLocaleString('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
    });
  }
  if (webinarEndDateText?.value) {
    webinarEndTimeLocal = new Date(webinarEndDateText?.value).toLocaleString('en-GB', {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
    });
  } else if (webinarEndDate?.value) {
    webinarEndTimeLocal = new Date(webinarEndDate?.value).toLocaleString('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  useEffect(() => {
    let details = "";
    if(!calendarText?.isLoading){
      const { eventDetailFormat, externalLinkLabel, hubLinkLabel } = calendarText?.content?.elements || {};
      const internalURL = hubLinkLabel?.value ? hubLinkLabel?.value : "Webinar URL";
      const externalUrl = externalLinkLabel?.value ? externalLinkLabel?.value : "Zoom Link";
      details = eventDetailFormat?.value;
      if(details){
        details = details.replace("{hubLink}", internalURL.link(window.location.href));
        details = details.replace("{externalLink}", externalUrl.link(webinarUrl?.value));
      }
    }
    let icsData = 
      "BEGIN:VCALENDAR\n" +
      "PRODID:-//AddEvent Inc//AddEvent.com v1.7//EN\n" +
      "VERSION:2.0\n" +
      "BEGIN:VEVENT\n" +
      "DTSTAMP:20220426T100129Z\n" +
      "STATUS:CONFIRMED\n" +
      "UID:"+webinarExternalId?.value+"\n" +
      "SEQUENCE:0\n" +
      "DTSTART;VALUE=DATE:" +
        convertDate(webinarDateText?.value || webinarDate?.value) +
      "\n" +
      "DTEND;VALUE=DATE:" +
        convertDate(webinarEndDateText?.value || webinarEndDate?.value || endDateIso) +
      "\n" +
      "SUMMARY:" +
        title?.value +
      "\n" +
      "DESCRIPTION:"+details+"\n" +
      "X-ALT-DESC;FMTTYPE=text/html: <html><body>"+details+"</body></html>\n" + 
      "LOCATION:Online\n" +
      "BEGIN:VALARM\n" +
      "TRIGGER:-PT15M\n" +
      "ACTION:DISPLAY\n" +
      "DESCRIPTION:Reminder\n" +
      "END:VALARM\n" +
      "TRANSP:OPAQUE\n" +
      "END:VEVENT\n" +
      "END:VCALENDAR";
      
    if(details) {
      details = details.replace(/\n/g, '')
    }

    icsData = "BEGIN:VCALENDAR\n" +
    "METHOD:PUBLISH\n" +
    "PRODID:-//AddEvent Inc//AddEvent.com v1.7//EN\n" +
    "VERSION:2.0\n" +
    "BEGIN:VEVENT\n" +
    "STATUS:CONFIRMED\n" +
    "UID:"+webinarExternalId?.value+"\n" + 
    "SEQUENCE:0\n" +
    "DTSTART;VALUE=DATE:" + convertDate(webinarDateText?.value || webinarDate?.value) + "\n" +
    "DTEND;VALUE=DATE:" + convertDate(webinarEndDateText?.value || webinarEndDate?.value || endDateIso) + "\n" +
    "SUMMARY:" + title?.value + "\n" +
    `DESCRIPTION:${convertHTMLToText(details)}\n` +
    `X-ALT-DESC;FMTTYPE=text/html:<html><body>${details}</body></html>\n` +
    "LOCATION: " + webinarUrl?.value + "\n" +
    "BEGIN:VALARM\n" +
    "TRIGGER:-PT15M\n" +
    "ACTION:DISPLAY\n" +
    "DESCRIPTION:Reminder\n" +
    "END:VALARM\n" +
    "TRANSP:OPAQUE\n" +
    "END:VEVENT\n" +
    "END:VCALENDAR";

    let data = new Blob([icsData], { type: "text/calendar" });
        
    const icsFile = window.URL.createObjectURL(data);
    const links = document.querySelectorAll(`[id^="downloadCalenderLink"]`)

    if (links?.length > 0) {
      links.forEach(link => {
        link.href = icsFile;
        link.download = `${title?.value}.ics`
      })
    }
  });

  const handleWebinarRedirect = () => {
    if (webinarUrl?.value && isWebinarActive) {
      trackJoinWebinarButton(userId, webinarExternalId?.value, title?.value);
      window.open(webinarUrl?.value, '_blank');
    }
  };

  let information = {
    webinarProvider: webinarProvider?.value,
    webinarExternalId: webinarExternalId?.value,
    submissionType: submissionType?.value,
    webinarName: title?.value,
  };

  const showCalenderDopdown = (e) => {
    e.preventDefault();

    setShowCalender((prev) => !prev);
  };
  const addToCalender = () => {
    trackAddwebinarToCalender(userId, title?.value);
  }
  const urlCalendar = "";

  const handleClickOutside = (event) => {
    if (calenderRef.current && !calenderRef.current.contains(event.target)) {
      setShowCalender(false);
    }
  }
  useEffect(() => {   
    if (showCalender) {
        document.addEventListener('click', handleClickOutside, true)
    }    
    return () => {
        document.removeEventListener('click', handleClickOutside, true)
    }
  }, [showCalender]);

  const defaultSettings = {
    dots: true,
    arrows: true,
    slidesToShow: 3,
    infinite: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1340,
        settings: { slidesToShow: 2, arrows: true },
      },
      {
        breakpoint: 950,
        settings: { slidesToShow: 1, arrows: false },
      },
    ],
  }

  return (
    <section
      role="main"
      className={`global_layout webinarDetailPage`}
      data-layout-type="dynamic-content-page"
      id="webinarDetail"
    >
      {headerLogo && (
        <section className="container">
          <div className="brand_logo_section">
            <Image data={headerLogo} />
          </div>
        </section>
      )}
      <WebinarBanner
        image={headerBackgroundImage}
        bannerText={bannerText ? bannerText : ""}
        displayText={displayDefaultHeaderText}
      />
      <div className="webinar-title-section-container">
        <MainAreaTitleSection
          title={title && title.value}
          category={subtitle && subtitle.value}
          backgroundHexColour={headerBackgroundHexColour}
          fontHexColour={headerTitleHexColour}
        />
      </div>
      <section className="content-section">
        <div className="content-section__inner">
          <div className="container">
            <div className="container_inner">
              <section className="content_wrapper content_wrapper--columns-2-left-webinar">
                {subnavigation?.value && (
                  <SideNavigation element={getValue(subnavigation)} />
                )}
              </section>
              <section className="content_wrapper content_wrapper--columns-1-center-webinar">
                <div className="webinar-tags-container">
                  <div className="webinar-tags">
                    <div className="webinar-tags__list">
                      {webinarDate && (
                        <div className="webinar-tags__list__detail">
                          <div className="webinar-tags__list__detail__icon">
                            <Calender />
                          </div>
                          <div className="webinar-tags__list__detail__text">
                            {getWebinarDate(webinarDate)}
                          </div>
                        </div>
                      )}
                      {webinarDate && (
                        <div className="webinar-tags__list__detail">
                          <div className="webinar-tags__list__detail__icon">
                            <Clock />
                          </div>
                          <div className="webinar-tags__list__detail__text">
                            {webinarTime?.value ? " "+webinarTime?.value : ""}
                          </div>
                        </div>
                      )}
                      {webinarType?.value && (
                        <div className="webinar-tags__list__detail">
                          <div className="webinar-tags__list__detail__icon">
                            <Location />
                          </div>
                          <div className="webinar-tags__list__detail__text">
                            {webinarType?.value}
                          </div>
                        </div>
                      )}
                      {!webinarOver && calenderList?.data?.documents && <div
                        className={
                          showCalender
                            ? `webinar-calendar calendar-shadow`
                            : `webinar-calendar`
                        }
                      >
                        <div
                          className="btn-add-calender"
                          onClick={showCalenderDopdown}
                          ref={calenderRef}
                        >
                          <div className="calendar-icon">
                            <Image
                              data={
                                calendarText?.content?.elements
                                  ?.calendarMainLogo
                              }
                            />
                          </div>
                          <a
                            href="#"
                            className="calender-link add-calendar-text"
                          >
                            {
                              calendarText?.content?.elements?.calendarMainLabel
                                ?.value
                            }
                          </a>
                        </div>
                        <nav className={`calender-dropdown ${
                              showCalender ? "calender-dropdown--show" : ""
                            }`}>
                          <ul
                            className={`slide ${
                              showCalender ? "show-calender" : ""
                            }`}
                          >
                            {calendarText?.content?.elements?.calendarOptions?.values.map(
                              (calendarOptionsValues, item) => {
                                if(calendarOptionsValues?.elements?.isDownload?.value){
                                  return (
                                    <li key={item}>
                                      <a
                                        href=""
                                        id={`downloadCalenderLink${item}`}
                                        target="_blank"
                                        className="calender-link dowload"
                                        onClick={addToCalender}
                                        // webinar details = {Description}
                                        // item id = uid
                                      >
                                        <div className="calender-link__icon">
                                          <Image
                                            data={
                                              calendarOptionsValues?.elements?.icon
                                            }
                                          />
                                        </div>
                                        <p>
                                          {calendarOptionsValues?.elements?.label?.value}
                                        </p>
                                      </a>
                                    </li>
                                  )
                                }
                                return (
                                  <li key={item}>
                                    <a
                                      href={`${getCalendarUrl(
                                        calendarOptionsValues?.elements
                                          ?.calendarLinkFormat?.value,information?.webinarName, calendarText?.content?.elements?.eventDetailFormat?.value, information?.webinarExternalId, convertDate(webinarDateText?.value || webinarDate?.value), convertDate(webinarEndDateText?.value || webinarEndDate?.value || endDateIso), webinarUrl?.value , calendarText?.content?.elements?.externalLinkLabel?.value, calendarText?.content?.elements?.hubLinkLabel?.value
                                      )}`}
                                      target="_blank"
                                      onClick={addToCalender}
                                      className="calender-link"
                                      // webinar details = {Description}
                                      // item id = uid
                                    >
                                      <div className="calender-link__icon">
                                        <Image
                                          data={
                                            calendarOptionsValues?.elements?.icon
                                          }
                                        />
                                      </div>
                                      <p>
                                        {calendarOptionsValues?.elements?.label?.value}
                                      </p>
                                    </a>
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </nav>
                      </div>}
                    </div>
                  </div>
                </div>
                <div className="content_item">
                  {enableDataCapture?.value && !webinarOver && (
                    <div className="webinar-sign-up">
                      {joinWebinarLabel && (hasRegistered || enableJoinBtn) && (joinPosition === "top" || joinPosition === "topAndBottom") && (
                        <button
                          className={`webinar-sign-up__btn${
                            isWebinarActive ? "" : " disabled join-webinar"
                          }`}
                          onClick={handleWebinarRedirect}
                        >
                          <span>
                            {joinWebinarLabel ? joinWebinarLabel : "Join Webinar"}
                          </span>
                        </button>
                      )}
                      {registrationError && (
                        <p className="webinar-sign-up__error">
                          {webinarRegistrationFailure
                            ? webinarRegistrationFailure
                            : "An unexpected error occurred"}
                        </p>
                      )}
                    </div>
                  )}
                  {!enableDataCapture?.value && isWebinarActive && !webinarOver && (joinPosition === "top" || joinPosition === "topAndBottom") && (
                    <div className="webinar-sign-up">
                      {joinWebinarLabel && isWebinarActive && (
                        <button
                          className={`webinar-sign-up__btn${
                            isWebinarActive ? "" : " disabled join-webinar"
                          }`}
                          onClick={handleWebinarRedirect}
                        >
                          <span>
                            {joinWebinarLabel ? joinWebinarLabel : "Join Webinar"}
                          </span>
                        </button>
                      )}
                    </div>
                  )}
                  {contentSection?.values && contentSection?.values.map((section, index) => (
                    <DynamicContentSection
                      element={section}
                      formHidden={true}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    />
                  ))}
                  {courseExternalLink?.value && (
                    <div className="accessCourse">
                      <AccessCourse
                        onClickPath={courseExternalLink?.value}
                        label={accessCourseDetailPageLabel}
                      />
                    </div>
                  )}
                </div>
                {relatedVideos?.value && (
                  <div className="content_item1">
                    <RelatedContent
                      bgColor="#ffffff"
                      headingText={
                        relatedVideos?.value?.elements?.relatedVideosHeading
                          ?.value
                      }
                      fluid
                    >
                      <Grid
                        wrapperStyle={{ marginTop: 40 }}
                        itemsPerRow={{ sm: 1, md: 2, lg: 3 }}
                        gap={{ sm: 20, md: 20, lg: 20 }}
                      >
                        {relatedVideos?.value?.elements?.relatedVideosVideoCards?.values.map(
                          (video) => {
                            const { id, lastModified } = video || {};

                            const {
                              videoCardContent,
                              videoCardHeading,
                              videoCardImage,
                              videoCardLink,
                              videoCardSubheading,
                              videoCardVideo,
                              externalVideoLink:videoCardExternalLink
                            } = video?.elements || {};
                            return (
                              <VideoCard
                                key={id}
                                href={videoCardLink?.linkURL}
                                image={videoCardImage}
                                video={videoCardVideo}
                                heading={videoCardHeading?.value}
                                subHeading={videoCardSubheading?.value}
                                content={videoCardContent?.value?.text}
                                postDate={lastModified}
                                externalVideoLink={videoCardExternalLink}
                              />
                            );
                          }
                        )}
                      </Grid>
                    </RelatedContent>
                  </div>
                )}
                {keynoteSpeakers?.values && (
                  <div className="keynote-speakers">
                    {webinarsKeynoteSpeakersLabel && (
                      <div className="keynote-speakers__label">
                        {webinarsKeynoteSpeakersLabel}
                      </div>
                    )}
                    <div className="keynote-speakers__list">
                      {keynoteSpeakers?.values.map((speaker, key) => {
                        return (
                          <WebinarSpeakers
                            key={key}
                            speaker={speaker}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {contentSection?.values && contentSection?.values.map((section, index) => {
                  if(section?.type === "Component – Form Builder") {
                    if(!enableDataCapture?.value) {
                      return (
                        <FormBuilder 
                          key={index} 
                          element={section} 
                          webinarProvider={webinarProvider?.value} 
                        />
                      );
                    }
                  }
                })}
                {enableDataCapture?.value && !webinarOver && (
                  <div className="webinar-sign-up">
                    {webinarSignUpLabel && displayRegBtn && !hasRegistered  && ( 
                      <>
                        <button
                          className={`webinar-sign-up__btn${
                            isSubmitting ? " disabled" : ""
                          }`}
                          disabled={isSubmitting}
                          onClick={handleConfirmation}
                        >
                          <span>{webinarSignUpLabel}</span>
                          {isSubmitting ? <Loader size={TINY_LOADER} /> : ""}
                        </button>
                      </>
                    )}
                    {joinWebinarLabel && (hasRegistered || enableJoinBtn) && (!joinPosition || joinPosition === "bottom" || joinPosition === "topAndBottom") && (
                      <button
                        className={`webinar-sign-up__btn${
                          isWebinarActive ? "" : " disabled join-webinar"
                        }`}
                        onClick={handleWebinarRedirect}
                      >
                        <span>
                          {joinWebinarLabel ? joinWebinarLabel : "Join Webinar"}
                        </span>
                      </button>
                    )}
                    {registrationError && (
                      <p className="webinar-sign-up__error">
                        {webinarRegistrationFailure
                          ? webinarRegistrationFailure
                          : "An unexpected error occurred"}
                      </p>
                    )}
                  </div>
                )}
                {!enableDataCapture?.value && isWebinarActive && !webinarOver && (!joinPosition || joinPosition === "bottom" || joinPosition === "topAndBottom") && (
                  <div className="webinar-sign-up">
                    {joinWebinarLabel && isWebinarActive && (
                      <button
                        className={`webinar-sign-up__btn${
                          isWebinarActive ? "" : " disabled join-webinar"
                        }`}
                        onClick={handleWebinarRedirect}
                      >
                        <span>
                          {joinWebinarLabel ? joinWebinarLabel : "Join Webinar"}
                        </span>
                      </button>
                    )}
                  </div>
                )}
              </section>
              <div className="content_wrapper content_wrapper--columns-2-right-webinar">
                
              </div>
            </div>
          </div>
          {registrationSuccess && (
            <div ref={registeredMessageRef} className="webinar-sign-up__success-modal">
              <div className="success-modal__content">
                <Tick />
                <p>
                  {webinarRegistrationSuccess
                    ? webinarRegistrationSuccess
                    : "You have successfully signed up for this webinar"}
                </p>
                <button
                  onClick={() => {
                    setRegistrationSuccess(false);
                  }}
                >
                  {webinarRegistrationSuccessCloseButtonLabel
                    ? webinarRegistrationSuccessCloseButtonLabel
                    : "Close"}
                </button>
              </div>
            </div>
          )}
        </div>
        {relatedWebinars && (
          <>
            <div className="webinar-cards-container">
              <RelatedContent
                componentType="webinar-card"
                content={relatedWebinars}
                bgColor="#ffffff"
                headingText={relatedWebinarsLabel}
                carouselSettings={redesign ? defaultSettings : null}
                fluid
              />
            </div>
            <div className="webinar-details-page-separator"></div>
          </>
        )}
        <div className="webinar-cards-container">
          <div className="content-separator"></div>
          {relatedContent && (
            <RelatedContent
              content={relatedContent}
              carouselSettings={redesign ? defaultSettings : null}
              headingText={
                relatedContentLabel?.value ? relatedContentLabel?.value : ""
              }
              fluid
            />
          )}
          {callout && 
            <>
              <div className="webinar-cards-container--callout">
                <div className="content-separator"></div>
                <PromoBanner content={callout} backgroundSize="auto" />
              </div>
            </>
          }
          {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} />}
          <div className="modal-references">
            {references && (
              <References
                references={references}
                veevaApprovalCode={veevaApprovalCode}
              />
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default WebinarDetailPage;
