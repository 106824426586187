import React, { useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';

// config
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig'
import { getValue } from 'utils/contentUtils'
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'redux/authentication/slice';


// modules
import RelatedContent from '../../../modules/relatedContent/RelatedContent'
import SideNavigation from '../../../modules/sideNavigation/SideNavigation'
import TextAndImagePanel from 'modules/textAndImagePanel/TextAndImagePanel';
import EapcSpeaker from 'components/content/eapc/EapcSpeaker';

import Link from 'components/reusableComponents/link/Link';
import Image from 'components/reusableComponents/image/Image';
import {Input} from '../../shared/form/Input';

import {
    Loader,
    TINY_LOADER,
} from 'components/shared/Loader';

// components
import { MainAreaTitleSection } from 'components/layout/MainAreaTitleSection'
import { BannerImage } from 'components/content/BannerImage'
import { BannerImageBackground } from 'components/content/eapc/BannerImageBackground'
import { HeaderBannerImageBg } from 'components/content/eapc/HeaderBannerImageBg'
import { AuthorSection } from 'components/content/article/AuthorSection'
import { References } from 'components/content/References'
import { DynamicArticleSection } from 'components/content/article/DynamicArticleSection'
import { PreviewIframe } from 'components/reusableComponents/preview-iframe/PreviewIframe'
import { ChaptersPagination } from '../../../modules/chaptersPagination/ChaptersPagination'
import { Form } from 'components/shared/form/Form';
import { eapcConfirmation } from '../login/LoginPage/components/KKI/formsElements';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { HOME_PAGE_PATH, REGISTRATION_SUCCESS } from 'routes/routeConstants';
import {event} from './formBuilder';
import useIpGeoLocationRedirect from './useIpGeoLocationRedirect';
import { EapcDynamicArticleSection } from 'components/content/article/EapcDynamicArticleSection';

// styles
import '../../../pages/dynamicArticlePage/DynamicArticlePage';

import './EventLandingPage.scss';
import { array } from 'prop-types';
import RichText from 'components/reusableComponents/richText/RichText';
import { Description } from '@material-ui/icons';
import { EapcImageCaption } from 'components/content/eapc/EapcImageCapton';
import { useEffect } from 'react';
import { eventRegistration } from 'api/eventsApi';
import { getUrlCountryPath } from 'utils/urlUtils';
import AdverseEventsCompliance from 'components/content/AdverseEventsCompliance';
import VeevaPortalViewer from 'modules/veevaPortalViewer/VeevaPortalViewer';



const Eapc = ({ content,template }) => {
    const {
        title,
        subtitle,
        logo,
        headerBackgroundImage,
        headerTitleHexColour,
        headerBackgroundHexColour,
        authors,
        date,
        articleSection,
        bannerImage,
        veevaApprovalCode,
        relatedContent,
        references,
        contentChapters,
        subnavigation,
        timeToRead,
        externalContentLink,
        externalContentImage,
        hidePreviewContent,
        formBuilder,
        currentLocale,
        alternateLinks,
        enableGeoipLocator,
        ipStackApiKey,
        description,
        featuredContent,
        brandColours,
        keySpeakersLabel,
        headerText,
        footerImageBanner,
        eventPicker,
        thankYouPage,
        enableDataCapture
    } = content.elements

    const errorResponse = formBuilder?.value?.elements?.errorResponse;
    const adverseEventsComplianceText = content?.elements?.basePage?.value?.adverseEventsComplianceText;

    const primaryColor = brandColours?.value?.elements?.primary
    const secondaryColor = brandColours?.value?.elements?.secondary
    const ctaButtonsBackground = brandColours?.value?.elements?.ctaButtonsBackground
    const eapcSpeakersBlocBackground = brandColours?.value?.elements?.eapcSpeakersBlocBackground
    const footerBannerBackground = brandColours?.value?.elements?.footerBannerBackground
    const footerBannerWithBackground = footerBannerBackground?.value ? true : false;
    const fieldStyle= {
        color:primaryColor?.value
    }

    const footerBannerBackgroundStyle = {
        backgroundColor: footerBannerBackground?.value || ""
    }

    const eapcsStyle = {
        backgroundColor: eapcSpeakersBlocBackground?.value || "",
    }

    const { trackEventWithMethod,trackRequestContent,trackEapcRegistration } = useGoogleAnalytics();
    const { tPublicationDate } = useWebsiteConfigObject()
    const dispatch = useDispatch();
    const formattedDate = date && date.value ? dayjs(date.value).format('D MMM YYYY') : null

    const dateStr = formattedDate ? `${tPublicationDate}, ${formattedDate}` : null
    const sectionElements = articleSection ? articleSection.values : null
    const chapter = contentChapters ? contentChapters.value : null

    const hasReferences = references && references.value

    const logoValue = logo?.value?.elements
    const timeReading = timeToRead?.value;
    
    const urlParams = new URLSearchParams(window.location.search);
  
    const formName = formBuilder?.value?.elements?.formName?.value
    const requiredLabel = formBuilder?.value?.elements?.requiredLabel;
    const invalidFieldLabel = formBuilder?.value?.elements?.invalidFieldLabel;

    const mandatoryLabel = formBuilder?.value?.elements.mandatoryLabel.value;
    const topLabel = formBuilder?.value?.elements?.formFooterDescription || "";

    const speakers = featuredContent;
    
    const { countryCodeName, ipAddress } = useIpGeoLocationRedirect(ipStackApiKey.value, enableGeoipLocator.value, alternateLinks, currentLocale);

    const history = useHistory();

    /**
     * session logic implementation
     */
    const [errorOccured, setErrorOccured] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    let eventSession = window.sessionStorage.getItem("events");
    const setSessionOnRegistration = (eventId) => {
        if(!eventSession) {
            let eventsRegistered = new Array();
            eventsRegistered.push(eventId);
            window.sessionStorage.setItem("events", JSON.stringify(eventsRegistered));
        }else {
            let eventsRegistered = JSON.parse(eventSession);
            if(!eventsRegistered.includes(eventId)){
                eventsRegistered.push(eventId);
                window.sessionStorage.setItem("events", JSON.stringify(eventsRegistered));
            }
        }
        history.push(thankYouPage?.value);
    };
    /**
     * Scroll down on click action
     */
    const scrollRef = useRef("test");
    const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: "smooth" });

    /**
     * form submition implementation
     */
    const eventId = eventPicker?.value?.elements?.eventId?.value;
    const eventType = formBuilder?.value?.elements?.submissionType?.value?.selection;
    const formFields = formBuilder?.value?.elements?.formBuilder?.values;

    let body = {};
    body["eventId"] = eventId;
    body["eventType"] = eventType;
    body["eventProvider"] = "hub";
    const handleConfirmation = (formValues) => {
        setIsSubmitting(true);
        let bodyFields = new Array();
        formFields.map((field) => {
            let fieldSubset = {};
            let fieldKey = field?.elements?.fieldKey?.value;
            let isPii = field?.elements?.isPii?.value;
            fieldSubset["fieldKey"] = fieldKey;
            fieldSubset["fieldValue"] = formValues[fieldKey];
            fieldSubset["isPii"] = isPii;
            bodyFields.push(fieldSubset);
        })
        urlParams.forEach((value, key) => {
            const isFormField = Boolean(bodyFields.filter((fieldSubset) => fieldSubset["fieldKey"].toLowerCase() === key.toLowerCase()
            ).length)
            if(!isFormField) {
                bodyFields.push({
                    "fieldKey": key,
                    "fieldValue": value,
                    "isPii": false
                })
            }
        })
        
        body["fields"] = bodyFields;
        eventRegistration({ body }).then((response) => {
            setIsSubmitting(false);
            if(response.data.status === 200) {
                trackEapcRegistration(response.data.id,title?.value);
                setSessionOnRegistration(eventId);
            }else {
                setErrorOccured(true);
            }
        })
        .catch((error) => {
            console.log(error)
            setIsSubmitting(false);
            setErrorOccured(true);
        });
        // dispatch(authActions.kki.login(email, lastName, firstname )).then(() => {
        //   trackEventWithMethod('confimation', 'KK Hub web');
        //   history.push(destination);
        // });
    };
    
      // need to create an external function to get the token
      const authObject = window.localStorage.getItem('persist:auth')
      if (!authObject) return false
      const auth = JSON.parse(authObject)
      const data = auth?.data
      const { token: _token } = JSON.parse(data) || {}
      const { token } = _token || {}

      let builder = formBuilder?.value?.elements?.formBuilder?.values
      let array = new Array();
      function getbuilder (){
        for (let i = 0; i< builder.length; i++){
           array.push(event(builder[i])) 
        }
      }
      if(enableDataCapture?.value) {
          getbuilder();
      }
      const btnBackground = ctaButtonsBackground?.value ?? primaryColor?.value;
      const submitBtn = {
        id: 'confirm-event',
        type: "button",
        size: 50,
        customiSSubmitting: isSubmitting,
        color: btnBackground || '',
        typeParams: {
                callBack: ({ formValues, actions }) => {
                actions.handleConfirmation(formValues);
            },
            text: formBuilder?.value?.elements?.submitButton?.value || "",
            type: 'submit',
        },
      }
      array.push(submitBtn);
      //request button 
      const handleRequestContent =() =>{
        executeScroll()
        trackRequestContent();
      }
      

    //get speakers list
    let speakersArray = [];
    if(featuredContent?.values){
        featuredContent?.values.map((item) => {
            if(item?.type === "Module - Webinar Speakers"){
                speakersArray.push(item)
            }
        })
    }
    return (
        <section role='main' className='global_layout' data-layout-type='dynamic-article-page' id="eapc">
            <div className="eapc-logo">
                <Image data={logo}/>
            </div>
           <section className= "bannerImage">
               <HeaderBannerImageBg image = {bannerImage}/>
           </section>
            <section className={`eapc-main-title-section${headerText?.value ? "" : " no-headline"}`}>
                <MainAreaTitleSection
                    title={title && title.value}
                    headline={headerText}
                    backgroundHexColour={primaryColor}
                    fontHexColour={secondaryColor}
                />
            </section>
            <section>
                <div className='container'>
                    <section className='container_inner'>
                            <div className='content_item '>
                                {sectionElements &&
                                    sectionElements.map((section, index) => (
                                        <DynamicArticleSection
                                            element={section}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                        />
                                    )) 
                                }
                                {description && (
                                    <div className="richtext-description">
                                        <RichText data={description}/>
                                    </div>
                                )}
                                {featuredContent?.values && featuredContent?.values.map((content,index) => {
                                    if(content?.type === "Module - Event Panel Text and Image"){
                                        return <TextAndImagePanel key={index} action={handleRequestContent} content={content} ctaBtnBackground={ctaButtonsBackground} />
                                    }
                                    if(content?.type === "Module - Veeva Portals Viewer") {
                                        return <VeevaPortalViewer element={content} />
                                    }
                                    if(content?.type === "Module - Content"){
                                        return (
                                            <EapcDynamicArticleSection
                                                element={content}
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                            />
                                        )
                                    }
                                })}
                            </div>
                    </section>
                </div>
                {speakersArray.length > 0 && 
                    <div className="eapc-speakers" style={eapcsStyle}>
                        {keySpeakersLabel?.value && 
                            <div className="speakers-title">
                                <h2>{keySpeakersLabel?.value}</h2>
                            </div>
                        }
                        {speakersArray && 
                            <div className="eapc-speakers-list">
                                {speakersArray.map((speaker,index) => {
                                    return <EapcSpeaker key={index} speaker={speaker} />
                                })}
                            </div>
                        }
                    </div>
                }
                <div className='container' ref={scrollRef} id="form">
                    <section className='container_inner'>
                            { enableDataCapture?.value &&
                                <div className="content_item" id="eapc-form">
                                {!errorOccured &&
                                    <>
                                        <div className="formName" > {formName}</div>
                                        <Form
                                            formId="eapc-form"
                                            fields={array}
                                            actions={{handleConfirmation}}
                                            requiredLabel={requiredLabel?.value}
                                            invalidFieldLabel={invalidFieldLabel?.value}
                                            wrapped
                                        />
                                        <div className="form-labels">
                                            <span className="mandatory">{mandatoryLabel}</span>
                                            {topLabel && <div className="form-bottom-label"><RichText data={topLabel} /></div>}
                                        </div>
                                    </>
                                }
                                {errorOccured && <div className="form-submission-error"><RichText data={errorResponse}/></div>}
                                </div>
                            }
                    </section>
                    {relatedContent && <RelatedContent content={relatedContent} fluid />}
                </div>
            </section>
            <section className={`footer-banner-container${footerBannerWithBackground ? " with-background" : ""}`} style={footerBannerBackgroundStyle}>
                <div className='container'>
                    <section className= "footer-bannerImage">
                        <BannerImageBackground image = {footerImageBanner} />
                    </section>
                    </div>
            </section>
            <section>
                <div className='container'>
                    {adverseEventsComplianceText?.value && <AdverseEventsCompliance data={adverseEventsComplianceText} veeva-container />}
                     <section className="container-references">
                        <References
                            references={references}
                            veevaApprovalCode={veevaApprovalCode}
                        />
                    </section>
                </div>
            </section>
        </section>
    )
}

export default Eapc